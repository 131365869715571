import React from "react";
import Footer from "../footer/Footer";
const Impressum = () => {
  return (
    <div>
      <div className="impressum">
      <p style={{ color: "#00387b", fontSize: 50, fontWeight: "bold" }}>
          IMPRESSUM
        </p>
        <pre />
        <p style={{ color: "#00387b", fontSize: 20 }}>
          <strong>ESNAD e.V </strong>
          <pre />
          Handelsregister- Amtsgericht Bonn,<pre />  VR 10468<pre /> 
          Steuernummer: 206/5859/0829
          <pre /> 
          ESNAD Organisation e.V.<pre /> 
          An der Nesselberg 97<pre /> 
          53179 Bonn<pre /> 
          info-eu@esnad.de<pre /> 
        </p>
        </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Impressum;
