import Footer from "../footer/Footer";
import { Helmet } from "react-helmet";
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom';
import pdf from "./../../assets/Antrag-Mitgleidschaft.pdf"
import "./Pages.css";
const Membership = () => {
  return (
    <div>
      <div className="about-membership">
        <Helmet>
          <title>
            Esnad e.V | Membership| Vorstand
          </title>
        </Helmet>
        <Trans i18nKey="Membership-Content" components={{ p: <p/> , pre: <pre/>, h4: <h4/>, ul: <ul/>, li: <li/>, strong: <strong/>, h5: <h5/>}} />
        <Link to={pdf} style={{ textDecoration: 'none' }} target="_blank" download><button type="button">Download</button></Link>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Membership;
