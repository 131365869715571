import Footer from "../footer/Footer";
import { Helmet } from "react-helmet";
import { Trans, useTranslation } from 'react-i18next'
import Card from "./Card"
import "./Pages.css";
const Projects = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="projects">
        <Helmet>
          <title>
            Esnad e.V | Projects| Projekte
          </title>
        </Helmet>
        <div className="cards">
           <Card key={0} text={t("Projects-History")} to={"history"} index={0} />
           <Card key={1} text={t("Current-Projects")} to={"current"} index={1} />
           <Card key={2} text={t("Year-Plan")} to={"plan"} index={2} />
        </div>
        <Trans i18nKey="Project-Content" components={{ p: <p/> , pre: <pre/>, h2: <h2/>, h4: <h4/>, ul: <ul/>, li: <li/>, strong: <strong/>, h5: <h5/>}} />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Projects;
