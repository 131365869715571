import React from "react";
import { NavLink } from "react-router-dom";
import "./Footer.css";
import CookieConsent, { OPTIONS } from "react-cookie-consent";
import Instegram from "../../assets/instegram.png";
import Thread from "../../assets/thread.png"
import { useTranslation } from 'react-i18next'
const Footer = () => {
  const {t} = useTranslation();
  return (
    <div>
      <div className="Footer">
        <div>
          <CookieConsent
            disableStyles={true}
            location={OPTIONS.BOTTOM}
            buttonClasses="btn btn-primary"
            containerClasses="alert alert-warning col-lg-12"
            contentClasses="text-capitalize"
          >
            Wir verwenden Cookies.
          </CookieConsent>
        </div>
        <div>
          <p>© 2024 ESNAD e.V</p>
          <a href="https://www.instagram.com/esnad_org/?igsh=eDc1YWZyYjY5MDdr" target="_balnk"><img width={40} height={40} src={Instegram} alt="Instegram" /> </a>
          <a href="https://www.threads.net/@esnad_org" target="_balnk"><img width={40} height={40} src={Thread} alt="Thread" /> </a>
          <p>
            <NavLink to="/impressum">{t("Imprint")}</NavLink> |
            <NavLink to="/datenschutz">{t("Data-Protection")}</NavLink>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
