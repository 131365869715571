import React from "react";
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';
import "./Pages.css";
//import pdf from "./../../assets/Antrag-Mitgleidschaft.pdf"
function Card({ text, index, to }) {
  return (
    <motion.div
      className="card"
      initial={{
        opacity: 0,
        // if odd index card,slide from right instead of left
        x: index % 2 === 0 ? 50 : -50
      }}
      whileInView={{
        opacity: 1,
        x: 0, // Slide in to its original position
        transition: {
            ease: "easeOut",
          duration: 2 // Animation duration
        }
      }}
      viewport={{ once: true }}
    >
    <Link to={to} style={{ textDecoration: 'none' }}><p className="card-text">{text}</p></Link>
    </motion.div>
  );
}

export default Card;

// {isFile ? (
      //<Link to={pdf} style={{ textDecoration: 'none' }} target="_blank" download><p className="card-text">{text}</p></Link>
      //) : (
      //)}