import React from "react";
import Footer from "../footer/Footer";
import { Helmet } from "react-helmet";
import { Trans } from 'react-i18next'
import logo from "../../assets/esnad-logo-1.png";
const Donation = () => {
  return (
    <div>
      <div className="donation">
        <Helmet>
          <title>
            Esnad e.V | Donation| Spende 
          </title>
        </Helmet>
        <img width={300} height={300} src={logo} alt="ESNAD e.V" />
        <Trans i18nKey="Donation-Content" components={{ p: <p/> , pre: <pre/>, h4: <h4/>, ul: <ul/>, li: <li/>, strong: <strong/>, h5: <h5/>}} />
        
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Donation;
