import React from "react";
import Footer from "../footer/Footer";
import ImageGallery from "react-image-gallery";
// import stylesheet if you're not already using CSS @import
import "react-image-gallery/styles/css/image-gallery.css";
import { Trans } from 'react-i18next'

const images = [
  {
    original: require("../../assets/image2.jpg"),
    thumbnail: require("../../assets/image2.jpg"),
  },
  {
    original: require("../../assets/image3.jpg"),
    thumbnail: require("../../assets/image3.jpg")
  },
  {
    original: require("../../assets/image4.jpg"),
    thumbnail: require("../../assets/image4.jpg")
  },
  {
    original: require("../../assets/image5.jpg"),
    thumbnail: require("../../assets/image5.jpg")
  },
  {
    original: require("../../assets/image6.PNG"),
    thumbnail: require("../../assets/image6.PNG")
  },
  {
    original: require("../../assets/image7.jpg"),
    thumbnail: require("../../assets/image7.jpg")
  },
  {
    original: require("../../assets/image8.jpg"),
    thumbnail: require("../../assets/image8.jpg")
  },
  {
    original: require("../../assets/image9.jpg"),
    thumbnail: require("../../assets/image9.jpg")
  },
  {
    original: require("../../assets/image10.jpg"),
    thumbnail: require("../../assets/image10.jpg")
  },
  {
    original: require("../../assets/image12.jpg"),
    thumbnail: require("../../assets/image12.jpg")
  },
  {
    original: require("../../assets/image13.PNG"),
    thumbnail: require("../../assets/image13.PNG")
  },
  {
    original: require("../../assets/image14.PNG"),
    thumbnail: require("../../assets/image14.PNG")
  },
  {
    original: require("../../assets/image15.JPG"),
    thumbnail: require("../../assets/image15.JPG")
  },
  {
    original: require("../../assets/image16.jpeg"),
    thumbnail: require("../../assets/image16.jpeg")
  },
  {
    original: require("../../assets/image17.jpeg"),
    thumbnail: require("../../assets/image17.jpeg")
  },
  {
    original: require("../../assets/image18.jpeg"),
    thumbnail: require("../../assets/image18.jpeg")
  },
  {
    original: require("../../assets/image19.jpeg"),
    thumbnail: require("../../assets/image19.jpeg")
  },
  {
    original: require("../../assets/image20.jpeg"),
    thumbnail: require("../../assets/image20.jpeg")
  },
  {
    original: require("../../assets/image21.jpeg"),
    thumbnail: require("../../assets/image21.jpeg")
  },
  {
    original: require("../../assets/image22.jpeg"),
    thumbnail: require("../../assets/image22.jpeg")
  },
  {
    original: require("../../assets/image23.jpeg"),
    thumbnail: require("../../assets/image23.jpeg")
  },
  {
    original: require("../../assets/image24.jpeg"),
    thumbnail: require("../../assets/image24.jpeg")
  },
  {
    original: require("../../assets/image25.jpeg"),
    thumbnail: require("../../assets/image25.jpeg")
  },
  {
    original: require("../../assets/image27.jpeg"),
    thumbnail: require("../../assets/image27.jpeg")
  },
  {
    original: require("../../assets/image28.jpeg"),
    thumbnail: require("../../assets/image28.jpeg")
  },
  {
    original: require("../../assets/image29.jpeg"),
    thumbnail: require("../../assets/image29.jpeg")
  },
  {
    original: require("../../assets/image30.jpeg"),
    thumbnail: require("../../assets/image30.jpeg")
  },
  {
    original: require("../../assets/image31.jpeg"),
    thumbnail: require("../../assets/image31.jpeg")
  },
  {
    original: require("../../assets/image32.jpeg"),
    thumbnail: require("../../assets/image32.jpeg")
  },
  {
    original: require("../../assets/image33.jpeg"),
    thumbnail: require("../../assets/image33.jpeg")
  }
];

const Gallary = () => {
  return (
    <div>
      <div className="gallary">
        <div>
          <p style={{ color: "#00387b", fontSize: 30, fontWeight: "bold" }}>
          <Trans i18nKey="Gallary-Text" components={{ p: <p/> , pre: <pre/>, h4: <h4/>, ul: <ul/>, li: <li/>, strong: <strong/>, h5: <h5/>, text: <text/>}} />          </p>
        </div>
        <div>
          <ImageGallery layout="columns" items={images}/>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Gallary;
