import React, { StrictMode } from "react";
import Footer from "../footer/Footer";
import "./Pages.css";
import homePage1 from "../../assets/homePage.png";
import homePage2 from "../../assets/homePage2.png";


import { Trans } from 'react-i18next'
const Home = () => {
  return (
    <StrictMode>
      <div className="home">
       <p><Trans i18nKey="Home-Welcome" components={{ p: <p/> , pre: <pre/>, h4: <h4/>, ul: <ul/>, li: <li/>, strong: <strong/>, h5: <h5/>, text: <text/>}} /></p>
       <Trans i18nKey="Home-Content-1" components={{ p: <p/> , pre: <pre/>, h4: <h4/>, ul: <ul/>, li: <li/>, strong: <strong/>, h5: <h5/>, text: <text/>}} />
       <img width={700} height={500} src={homePage1} alt="Projekt Foto" />
       <Trans i18nKey="Home-Content-2" components={{ p: <p/> , pre: <pre/>, h4: <h4/>, ul: <ul/>, li: <li/>, strong: <strong/>, h5: <h5/>, text: <text/>}} />
       <img width={700} height={500} src={homePage2} alt="Projekt Foto" />
       <Trans i18nKey="Home-Content-3" components={{ p: <p/> , pre: <pre/>, h4: <h4/>, ul: <ul/>, li: <li/>, strong: <strong/>, h5: <h5/>, text: <text/>}} />
       </div>
      <div>
        <Footer />
      </div>
    </StrictMode>
  );
};

export default Home;
