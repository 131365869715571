import React, {useState} from "react";
import i18n from '../i18n';
import Flag from '../i18n/Flag';
import de from '../assets/de.png'
import ye from '../assets/ye.png'



const LanguageSelector = () => {
    
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language); // i18n.language contains the language assigned to lng in i18n.js file.

    const chooseLanguage = (value) => {
        i18n.changeLanguage(value);   // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
        setSelectedLanguage(value);
        localStorage.setItem("lang", value);
    }

    return (
        <div >
      <Flag
        image={de}
        alt='DE'
        isSelected={selectedLanguage === 'de-DE'} // check if pt-BR is selected
        onClick={() => chooseLanguage('de-DE')} // changes the language to pt-BR
      />
      {/* <Flag
        image={uk}
        alt='UK'
        isSelected={selectedLanguage === 'en-EN'} // check if en-US is selected
        onClick={() => chooseLanguage('en-EN')} // changes the language to en-US
      /> */}
      <Flag
        image={ye}
        alt='YE'
        isSelected={selectedLanguage === 'ye-YE'} // check if en-US is selected
        onClick={() => chooseLanguage('ye-YE')} // changes the language to en-US
      />
    </div>
        
    );
};

export default LanguageSelector;