import { BrowserRouter, NavLink, Route, Routes } from "react-router-dom";
import React, { useState } from "react";
import logo from "../../assets/esnad-logo.png";

import "./navbar.css";
import { Sling as Hamburger } from "hamburger-react";
import Home from "../pages/Home";
import Contact from "../pages/Contacts";
import Projects from "../pages/Projects";
import About from "../pages/About";
import Donation from "../pages/Donation"
import Gallary from "../pages/Gallary";
import Impressum from "../pages/Impressum";
import Datenschutz from "../pages/Datenschutz";
import LanguageSelector from '../LanguageSelector';
import { useTranslation } from 'react-i18next'
import Board from "../pages/Board";
import ProjectsHistory from "../pages/ProjectsHistory";
import CurrentProjects from "../pages/CurrentProjects";
import YearPlan from "../pages/YearPlan";
import Constitution from "../pages/Constitution";
import Membership from "../pages/Membership";
const Navbar = () => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const handleNavBar = () => {
    setOpen(!isOpen);
  };
  return (
    <BrowserRouter>
      <nav className="navbar">
        <div className="container">
          
          <div>
            <NavLink to="/">
              <img width={200} height={200} src={logo} alt="ESNAD e.V" />
            </NavLink>
          </div>
          <div className="menu-icon" onClick={handleNavBar}>
            <Hamburger color="#ffffff" toggled={isOpen} toggle={setOpen} />
          </div>
          <div className={`nav-elements  ${isOpen && "active"}`}>
            <ul>
              <li>
                <NavLink to="/">{t("Home")}</NavLink>
              </li>
              <li>
                <NavLink to="/about">{t("About")}</NavLink>
              </li>
              <li>
                <NavLink to="/projects">{t("Projects")}</NavLink>
              </li>
              <li>
                <NavLink to="/gallary">{t("Gallary")}</NavLink>
              </li>
              <li>
                <NavLink to="/contact">{t("Contact")}</NavLink>
              </li>
              <li>
                <NavLink to="/donation">{t("Donation")}</NavLink>
              </li>
              <li>
                <NavLink ></NavLink>
              </li>
            </ul>
          </div>
          <LanguageSelector />
        </div>
      </nav>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route index element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/projects" element={<Projects />} />
        <Route exact path="/projects/history" element={<ProjectsHistory />} />
        <Route exact path="/projects/current" element={<CurrentProjects />} />
        <Route exact path="/projects/plan" element={<YearPlan />} />
        <Route exact path="/gallary" element={<Gallary />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/about/board" element={<Board />} />
        <Route exact path="/about/constitution" element={<Constitution />} />
        <Route exact path="/about/membership" element={<Membership />} />
        <Route exact path="/donation" element={<Donation />} />
        <Route exact path="/impressum" element={<Impressum />} />
        <Route exact path="/datenschutz" element={<Datenschutz />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Navbar;
