import Footer from "../footer/Footer";
import { Helmet } from "react-helmet";
import { Trans } from 'react-i18next'
import "./Pages.css";
const ProjectsHistory = () => {
  return (
    <div>
      <div className="projects-history">
        <Helmet>
          <title>
            Esnad e.V | Projects| Projekte
          </title>
        </Helmet>
        <Trans i18nKey="Projects-History-Content" components={{ p: <p/> , pre: <pre/>, h2: <h2/>, h4: <h4/>, ul: <ul/>, li: <li/>, strong: <strong/>, h5: <h5/>}} />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default ProjectsHistory;
