import React from "react";
import Footer from "../footer/Footer";
import { Trans } from 'react-i18next'
import "./Pages.css";
const Datenschutz = () => {
  return (
    <div className="datenschutz">
      <div>
        <p style={{ color: "#00387b", fontSize: 50, fontWeight: "bold" }}>
        <Trans i18nKey="Data-Protection-Title" components={{ p: <p/> , pre: <pre/>, h4: <h4/>, ul: <ul/>, li: <li/>, strong: <strong/>, h5: <h5/>, text: <text/>}} />
        </p>
        
      </div>
      <div>
        <p
          style={{
            color: "#00387b",
            fontSize: 15,
            paddingLeft: 150,
            paddingRight: 150,
          }}
        >
          <Trans i18nKey="Data-Protection-Content" components={{ p: <p/> , pre: <pre/>, h4: <h4/>, ul: <ul/>, li: <li/>, strong: <strong/>, h5: <h5/>, text: <text/>}} />

        </p>
        </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Datenschutz;
