import Footer from "../footer/Footer";
import { Helmet } from "react-helmet";
import "./Pages.css";
// Import styles

import Satzung from "./../../assets/Satzung.pdf"
const Constitution = () => {
  return (
    <div>
      <div className="about-constitution">
        <Helmet>
          <title>
            Esnad e.V | Constitution | Satzung
          </title>
        </Helmet>
        <iframe width="80%" height="1100px"  src={Satzung} title="Satzung">
          Presss me: <a href={Satzung}>Satzung hierrunterladen</a>
        </iframe>
        
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Constitution;
